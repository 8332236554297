import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges
} from '@angular/core';
import { FormControl } from '@angular/forms';

export interface Styles {
  fontSize?: string;
  backgroundColor?: string;
  margin?: string;
}

@Component({
  selector: 'ng-rating-bar',
  template: `<ng-container *ngFor="let item of numbers; let i = index">
  <span
    class="star"
    (mouseenter)="enter(i)"
    (mouseleave)="leave(i)"
    (click)="setSelected(i)"
    [ngStyle]="{'color': (i <= hoverIndex) ? colorActive : colorDefault}"
    [style.fontSize]="styles.fontSize"
    [style.backgroundColor]="styles.backgroundColor"
    [style.marginRight]="styles.margin"
  >
  â
  <span
    class="star half"
    *ngIf="!isHovered && i == halfIndex"
    [style.width.%]="halfValue"
    [style.color]="colorActive"
  >â</span>
</span>
</ng-container>
`,
  styles: [`.star{position:relative}.star:not(.half){min-height:20px;min-width:20px;cursor:pointer;display:inline-block}.star.active{color:inherit}.star.half{height:inherit;font-size:inherit;cursor:pointer;position:absolute;left:0;top:0;display:inline-block;overflow:hidden}`]
})
export class NgRatingBarComponent implements OnInit, OnChanges {

  @Input() ratingCount: number;
  @Input() colorActive: string;
  @Input() colorDefault: string;
  @Input() disabled: boolean;
  @Input() resetAble: boolean;

  @Input() control: FormControl;

  @Input() styles: Styles;

  @Input() value: number;
  @Output() valueChange: EventEmitter<number> = new EventEmitter<number>();
  @Output() hoverChange: EventEmitter<number> = new EventEmitter<number>();

  numbers = [];
  hoverIndex = -1;
  selectedValue = 0;
  halfValue = 0;
  halfIndex = -1;
  isHovered = false;
  constructor() {}

  ngOnInit() {
    this.ratingCount = this.ratingCount || 5;
    this.colorActive = this.colorActive || '#edb867';
    this.colorDefault = this.colorDefault || '#d2d2d2';


    if (!this.styles) {
      this.styles = {
        fontSize: '28px',
        backgroundColor: '',
        margin: '5px'
      };
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.value || this.control) {
      this.initNumbers();
      this.calculateHalfValue();
    }
  }

  initNumbers() {
    this.numbers = Array(this.ratingCount)
      .fill(0)
      .map((x, i) => i);
    if (this.control) {
      this.selectedValue = this.control.value;
    } else {
      this.selectedValue = this.value;
    }

    this.hoverIndex = this.selectedValue - 1;
  }

  enter(i: number) {
    if (this.disabled) {
      return;
    }
    this.isHovered = true;
    this.hoverIndex = i;
    this.hoverChange.emit(1 + i);


  }
  leave(i: number) {
    if (this.disabled) {
      return;
    }
    this.isHovered = false;
    this.hoverIndex = this.selectedValue - 1;
  }

  setSelected(i: number) {
    if (this.disabled) {
      return;
    }

    if (this.resetAble && this.selectedValue === i + 1) {
      this.selectedValue = 0;
    } else {
      this.selectedValue = i + 1;
    }

    if (this.control) {
      this.control.setValue(this.selectedValue || null);
      this.control.markAsTouched();
    } else {
      this.valueChange.emit(this.selectedValue);
    }
    this.isHovered = false;
    this.calculateHalfValue();
  }

  calculateHalfValue() {
    this.halfValue = Math.round(100 * (this.selectedValue - Math.floor(this.selectedValue)) );
    this.halfIndex = Math.ceil(this.selectedValue) - 1;
  }


}
