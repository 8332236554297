import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgRatingBarComponent } from './ng-rating-bar.component';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [NgRatingBarComponent],
  exports: [NgRatingBarComponent]
})
export class NgRatingBarModule { }
